.mainbooking {
    /* background-color: #F1EBFF; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: fit-content;
    width: 100%;
    padding: 40px 0;

}

.mainbookingbox {
    background-color: #6E4E9F;
    background-image: url("/public/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: fit-content;
    height: fit-content;
    padding: 40px;
    display: flex;
    border-radius: 20px;
    transition: 2s ease;
}

.bookingleft {
    width: 350px;
    /* padding: 20px; */
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 10%;
    font-size: small;
    gap: 20px;
}


.bookingright {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
}








.calendar table {
    /* width: 100%; */
    /* border-collapse: collapse; */
    height: 200px;
}





.calendar th {
    color: #6E4E9F;
}

.calendar td {
    /* border: 1px solid #ccc; */
    /* padding: 10px; */
    text-align: center;
    /* color: #6E4E9F; */
    /* background-color: #007bff; */
}

.calendar {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 5px 0.05px #6E4E9F;
    border-radius: 20px;
    /* Light grey background for header */
}

tr td {
    /* background-color: #c70808; */
    color: gray;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 10px;
    /* cursor: pointer; */
    transition: 0.5s ease;

}

/* tr td:hover{
    background-color: #00000031;
    color: rgb(0, 0, 0);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 10px ;
    cursor: pointer;

} */


.selecttime {
    /* background-color: yellow; */
    width: 300px;
    padding: 0px 20px;
    /* height: 60vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;


}

.times {
    background-color: #fff;
    height: 500px;
    border-radius: 20px;
    /* overflow-y: auto; */
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding: 20px 15px;
    color: #6E4E9F;
    box-shadow: 0px 0px 5px 0.05px #6E4E9F;
}

.times2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    padding: 0 5px;
    /* font-weight: ; */
}


.timebox {
    /* background-color: violet; */
    border-radius: 10px;

    border: 1px solid #6E4E9F;
    color: #6E4E9F;
    padding: 10px 0;
    cursor: pointer;

}

.todaydate {
    font-weight: bold;
    position: relative;
}

.todaydate::after {
    content: "";
    width: 50%;
    height: 2px;
    background-color: #6E4E9F;
    position: absolute;
    bottom: 0;
    left: 20%;
}

.sele {
    /* border: none; */
    /* padding: 0 40px; */
    /* display: flex; */
    /* align-items: center; */
    background-color: #6E4E9F;
    color: #fff;
}


:where(.css-dev-only-do-not-override-apn68).ant-image .ant-image-mask {
    position: absolute;
    inset: 0;
    border-radius: 50%;
    display: flex
;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
}

.sele:hover {
    background-color: #6E4E9F !important;
    color: #fff;
    cursor: auto;
}

.confirmbtn {
    /* margin-bottom: -40px; */
    /* margin-top: -25px; */
    /* background-color: #6E4E9F; */
    position: absolute;
    width: 85%;
    top: 92%;
    /* bottom: 0; */
}

.confirmbtn2 {
    margin-top: 10px;
    margin-bottom: -40px;
    /* background-color: #6E4E9F; */
    display: flex;
    justify-content: center;
    /* background-color: transparents; */
}

.styleeee {
    background-color: #fff;
    color: #4180B7;
    /* font-weight: 900; */
    pointer-events: none;
}

.timezone {
    padding: 10px 20px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #6E4E9F;
    color: #6E4E9F;


}

.calendar-controls {
    display: flex;
    /* background-color: #6E4E9F; */
    flex-direction: row;
    justify-content: end;
}

.calendar-controls select {
    padding: 10px 20px;
    width: 35%;
    border-radius: 10px;

}

.timebox:hover {
    background-color: #4180B7;
    color: #fff;
}

.timeboxselected {
    /* background-color: #6E4E9F; */
    display: flex;

}

.timeboxselected {
    border-radius: 10px;
    border: 1px solid #6E4E9F;
    color: #6E4E9F;
}

.detailsform {
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    color: #6E4E9F;
    width: 400px;
    
    padding: 20px 5%;

}

.detailsform div {
    display: flex;
    text-align: left;
    flex-direction: column;
}

.detailsform div input {
    padding: 10px 5px;
    border-radius: 10px;
    border: 1px solid rgba(128, 128, 128, 0.322);

}

.detailsform div select {
    padding: 10px 5px;
    border-radius: 10px;
    border: 1px solid rgba(128, 128, 128, 0.322);

}

.detailsform div textarea {
    padding: 10px 5px;
    border-radius: 10px;
    height: 150px;
    border: 1px solid rgba(128, 128, 128, 0.322);

}

.hover {
    cursor: pointer;
}


.alert {
    background-color: rgba(0, 0, 0, 0.74);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 100;
}

.alertbox {
    background-color: #bba6da;
    position: fixed;
    width: 20%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    top: 20%;
    z-index: 400;
    color: #fff;
    padding: 4%;
    border-radius: 10px;
    z-index: 100;
}

.rebin{
    background-color: #6E4E9F;
    width: 96%;
    left: 0px;
    /* text-align: left; */
    color: #fff !important;
    position: absolute;
    top: -25px;
    font-weight: bold;
    padding: 2%;
    border-radius:20px 20px 0 0;
}



.rebin2{
    background-color: transparent;
    width: 96%;
    left: 0px;
    /* text-align: left; */
    color: #6E4E9F !important;
    position: absolute;
    top: -55px;
    font-weight: bold;
    padding: 2%;
    border-radius:20px 20px 0 0;
    z-index: 20;
}
  



@media screen and (max-width:1060px) {
   
    .mainbooking {
        /* background-color: #F1EBFF; */
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
    
    }

    .alertbox {
        width: 90%;
    }
    .mainbookingbox {
       
        width: 95%;
        flex-direction: column;
        justify-content: baseline;
    }

    .bookingleft {
        width: 90%;
        padding-left: 0%;
    }

    .bookingright {
        width: 90%;
        padding: 0;
    }

    .detailsform {
        width: 90%;
        /* padding: 0; */
    }


    .calendar {
        margin-top: 10px;
        width: 100%;
    }

    .selecttime{
        margin-top: 50px;
        /* width: 100%; */
        /* padding: 0; */
    }

    .confirmbtn{
        top: 95%;
    }

    .userboxabout {
        height: 140px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        line-height: 1.4;
    }


    .uperuser {
        width: 87%;
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 20px;
    }


    .mainindex{
        /* background-color: #6E4E9F; */
        padding: 0 20px;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }
    .userbox2{
margin-bottom: 50px;
    }

    .userbox{
        margin-bottom: 50px;
            }
}