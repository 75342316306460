.mainindex {
    /* background-color: rgb(51, 255, 0); */
    height: fit-content;
    min-height: 96vh;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px 10vh;
    flex-wrap: wrap;
    gap: 50px;
    padding: 50px 0;
}

.mainindex2 {
    /* background-color: aqua; */
    height: fit-content;
    min-height: 96vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 10px 10vh; */
    flex-wrap: wrap;
    gap: 50px;
    padding: 40px 0;
}

.seeteammeain{
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: #6E4E9F; */

}

.userbox {
    /* background-color: #F1EBFF; */
    width: 320px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
    /* text-align: left; */
    border-radius: 20px;
    background-image: url("/public/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
    position: relative;
    /* transition: 0.5s ease; */
}


.userbox22 {
    /* background-color: #F1EBFF; */
    width: 100%;
    padding: 10px;
    display: flex;
    color: #6E4E9F;
    /* flex-direction: column; */
    text-align: left;
    align-items: center;
    gap: 70px;
    /* text-align: left; */
    border-radius: 20px;
    background-image: url("/public/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: fit-content;
    /* transition: 0.5s ease; */
}

.uperuser{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 20px;
}




.userbox2 {
    /* background-color: #F1EBFF; */
    width: 420px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    /* text-align: left; */
    border-radius: 20px;
    /* background-image: url("/public/bg.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
    transition: 0.5s ease;
}


.userbox h2,.userbox p {
    color: #6E4E9F;
}


.userbox2 h2,.userbox2 p {
    color: #6E4E9F;
}
.insidebox{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.insidebox2{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
/* .userbox:hover {
    box-shadow: 10px 10px 10px 5px #6E4E9F;
    scale: 1.005;
} */

.userboxorg {
    color: gray;
}

.userboxabout {
   
    height: 90px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
   
    line-height: 1.4;
   

}

.shed {
    background-color: #6E4E9F;
    color: #fff;
    cursor: pointer;
}

.shed:hover {
    background-color: #ab8add;
    color: #fff;
    cursor: pointer;
}
.ppppp{
    min-height: 80px;
    font-size: large;
    font-weight: 700;
}

.formalbtn {
    background-color: #6E4E9F;
    color: #fff;
    width: fit-content;
    padding: 15px 20px;
    border-radius: 50px;
    border: 1px solid #6E4E9F;
    /* margin-top: 20px; */
    cursor: pointer;
    font-weight: 600;
    transition: 0.5s ease;
    /* letter-spacing: 0.5px; */
}

.formalbtn:hover {
    background-color: #4180B7;
    border: 1px solid #4180B7;
    /* color: #4180B7; */

}