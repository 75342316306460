.App {
  text-align: center;
  font-family: 'Poppins';
}


*{
  margin: 0;
  padding: 0;
}




::-webkit-scrollbar {
  width: 0.7vw; /* Width for vertical scrollbar */
  height: 0.7vw; /* Height for horizontal scrollbar */
  background: #fff; /* Background of the scrollbar track */
  border-radius: 20px;
}

/* Vertical Scrollbar Thumb */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, transparent 0%, #6e4e9f 100%); /* Gradient from transparent to color */
  border-radius: 20px;
  min-height: 200px; /* Set minimum height for the vertical scrollbar thumb */
}

/* Horizontal Scrollbar Thumb */
::-webkit-scrollbar-thumb:horizontal {
  background: linear-gradient(to right, transparent 0%, #6e4e9f 100%); /* Gradient for horizontal scrollbar */
  border-radius: 20px;
  min-width: 200px; /* Set minimum width for the horizontal scrollbar thumb */
}

/* Hover effect for Vertical Scrollbar Thumb */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, transparent 0%, #4180B7 100%); /* Change color on hover for vertical */
}

/* Hover effect for Horizontal Scrollbar Thumb */
::-webkit-scrollbar-thumb:horizontal:hover {
  background: linear-gradient(to right, transparent 0%, #4180B7 100%); /* Change color on hover for horizontal */
}
